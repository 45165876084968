/*!
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

@import "base/variables";
@import "base/mixins";
@import "base/fonts";
@import "base/variables";
@import "components/Navbar";
@import "components/TicketInfoBanner";
@import "components/FeedbackForm";
@import "components/container";
@import "components/SideNavbar";
@import "components/DataContainer";
@import "components/FindingsTable";
@import "components/Modal";
@import "components/ModalTable";
@import "components/SearchBar";
@import 'components/ContributionComponent';
@import 'components/DefectManagement';
@import 'components/AIAssistant';
@import 'components/Pagination';
@import 'components/DropDownFilter';
@import 'components/FormView.scss';
@import 'components/Banner';
@import 'components/BannerForm';
@import 'components/AccountDetailsModal';
@import 'components/CommonFilter';
@import 'components/CFSTool';
@import 'Pages/UserHomePage';
@import "components/Recents";
@import 'Pages/AccountDetailsPage';

body {
  font-weight: 400;
  font-style: normal;
  font-size: px-to-rem(16px);
}
html {
  font-size: var(--base-font-size);
}
.headerStick {
  position: sticky;
  top: 0;
}
// Make All Table Header Backgrounds Transparent. Table Header will inherit the background color of the parent container.
[class*="spectrum-Table-headCell"] {
  background-color: transparent !important;
}
.cursor_Hand {
  cursor: pointer;
  border-color: #d3d3d3;
  margin-bottom: 20px;
}
em {
  font-style: italic;
  font-family: $font-primary;
}
.table-container > div[class$="_spectrum-Table-headWrapper"] {
  overflow: visible !important;
}

.response {
  overflow-y: auto;
  pre {
    padding: .5em;
    text-wrap: wrap;
    white-space: pre-wrap;
    border: 1px dotted gray;
    background: #f9f9f9;
  }
}

// Textareas should be 5 lines tall. This isn't supported by React Spectrum by setting height={"size-1600"} in the JSX.
// This hack overrides Spectrum's styles. Use the classname twice to increase specificity.
.tall-textarea.tall-textarea {
  width: 100%;
  textarea {
    // Textareas should be at least five lines tall. Account for one line of padding on each end (top and bottom)
    min-height: ($base-font-size * (5 + 2));
  }
}

/* WebKit-based browsers (Chrome, Safari, Edge Chromium) */
*::-webkit-scrollbar {
  width: 5px;                     /* Width of the entire scrollbar */
  height: 5px;                    /* Height of the scrollbar for horizontal scrolling */
}

*::-webkit-scrollbar-track {
  background: transparent;         /* Background color of the track area */
  box-shadow: none;                /* Remove shadow */
  border-radius: 10px;             /* Rounded edges */
}

*::-webkit-scrollbar-thumb {
  background-color: #D5D5D5;          /* Color of the scroll thumb */
  border-radius: 50%;             /* Roundness of the scroll thumb */
  border: 3px solid transparent;   /* Creates padding around the thumb */
  background-clip: padding-box;    /* Ensures the thumb doesn't bleed into border */
}

/* Firefox */
* {
  scrollbar-width: thin;           /* Thin scrollbar width */
  scrollbar-color: #D5D5D5 transparent; /* Thumb color and transparent track */
}
